<template>
  <div class="work animate__animated animate__fadeIn animate__slow">
    <section class="hero is-medium">
      <!-- Hero head: will stick at the top -->
      <div class="hero-head">
        <div class="container is-max-widescreen">
          <Navbar />
        </div>
      </div>

      <!-- Hero content: will be in the middle -->
      <div class="hero-body">
        <div class="container is-max-widescreen">
          <div class="columns">
            <div class="column">
              <div class="content">
                <p
                  class="title is-size-1-mobile has-text-black"
                  style="font-size: 4rem"
                >
                  Other Works
                </p>
                <p class="subtitle">Concepts design, design exercises, and Redesign.</p>
              </div>
            </div>
          </div>

          <hr class="is-hidden-tablet" />

          <!-- <figure class="image is-128x128">
            <img src="img/demo.usdz" />
          </figure> -->
        </div>
      </div>
    </section>

    <div class="container is-fluid">
      <div class="container is-max-widescreen">
        <Works />
      </div>
    </div>

    <!-- <div class="is-about-bg">
      <figure class="image">
        <img src="img/about-bg.svg" />
      </figure>
    </div> -->

    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Works from "@/components/Works.vue";
export default {
  name: "work",
  props: {
    msg: String,
  },
  components: {
    Navbar,
    Works,
    Footer,
  },
  mounted() {
    this.track();
  },
  methods: {
    track() {
      this.$gtag.pageview("/work");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'Work',
      })
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .is-about-bg {
  position: absolute;
  top:0;
  right:0;
  z-index: -1;
} */
</style>

