<template>
  <div class="works">
    <div class="columns is-multiline is-variable is-4">
      <div
        class="column is-half animate__animated animate__fadeInUp animate__slow"
        v-show="items.isWorks"
        v-for="(items, index) in worksList"
        :key="index"
      >
        <div class="columns my-3" @click="handleViewWork(items.path)">
          <!-- <div class="column is-one-quarter">
            <section class="section p-0">
              <p class="subtitle is-size-6 has-text-weight-bold has-text-black">
                {{ items.name }}
              </p>
              <hr width="50px" />
              <div class="tags">
                <span
                  class="tag is-light"
                  v-for="item in items.tags"
                  :key="item"
                  >{{ item }}</span
                >
              </div>
            </section>
          </div> -->
          <div class="column">
            <figure class="image">
              <img class="img-radius-10 img-border" :src="items.cover" />
            </figure>
            <section class="section p-0 my-3">
              <span v-show="items.isComingSoon" class="navbar-item tag is-light is-primary" style="float:right;">Coming Soon</span> 
              <p class="heading">{{ items.cate }}</p>
              <p class="is-size-4 has-text-weight-bold has-text-black">
                {{ items.name }}
              </p>
              <hr width="50px" class="my-3" />
              <div class="columns">
                <div class="column is-10">
                  <div class="tags">
                    <span
                      class="tag is-light"
                      v-for="item in items.tags"
                      :key="item"
                      >{{ item }}</span
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import source from "../views/data/works.json";
export default {
  name: "works",
  props: {
    msg: String,
  },
  data() {
    return {
      worksList: source,
    };
  },
  methods: {
    handleViewWork(path) {
      this.$router.push(path);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
